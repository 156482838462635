import React from "react"
import { getCurrentUser } from "../utils/auth"
import Hero from '../components/hero'

const Profile = () => (
 
    <Hero title="Profile" content={
        <ul>
        <li>Name: {getCurrentUser().name}</li>
        <li>E-mail: {getCurrentUser().email}</li>
        </ul>
    } />
    
 
)

export default Profile