import React from 'react'
import { Link } from 'gatsby'
// import get from 'lodash/get'
import { getCurrentUser, isLoggedIn } from "../utils/auth"

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'


const Home = () => (
 <div>
    <Seo title="Home" />
    <Hero title="Home" content=
      {isLoggedIn() ? (
        <>
          You are logged in, so check your{" "}
          <Link to="/app/profile">profile</Link>
        </>
      ) : (
        <>
          You should <Link to="/app/login">log in</Link> to see restricted
          content
        </>
      )}
    />
  </div>
)

export default Home


