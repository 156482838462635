import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
// import Details from "../components/Details"
import Profile from "../components/Profile"
import Home from "../components/Home"
import Login from "../components/Login"
import SignUp from "../components/SignUp"
import PrivateRoute from "../components/PrivateRoute"


class App extends React.Component {
    render() {
      return(
        <Layout>
            <Router>
                <PrivateRoute path="/app/home" component={Home} />
                <PrivateRoute path="/app/profile" component={Profile} />
                <Login path="/app/login" />
                <SignUp path="/app/signup" />
            </Router>
        </Layout>
      )
    }
}

// const App = () => (
//   <Layout location={this.props.location}>
//     <Router>
//       <PrivateRoute path="/app/home" component={Home} />
//       {/* <PrivateRoute path="/app/profile" component={Details} /> */}
//       <Login path="/app/login" />
//       <SignUp path="/app/signup" />
//     </Router>
//   </Layout>
// )

export default App